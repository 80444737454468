<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card ref="filterCard" v-bind:title="$t('Filters')" class="user-list-filters mb-8" collapse-action>
      <div class="vx-row">
        <!-- CountryID -->
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Country") }}</label>
          <v-select label="Name" class="w-full" v-model="DoctorSearch.CountryID" :options="countries"
            :reduce="(ID) => ID.ID" />
        </div>
        <!-- languageIDs -->
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Language") }}</label>

          <v-select label="Name" class="mw-full" multiple v-model="DoctorSearch.languageIDs" :options="languages"
            :reduce="(ID) => ID.ID" />
      </div>
      <!-- SpecialtyIDs -->
      <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
        <label class="text-sm opacity-75">{{ $t("Speciality") }}</label>

        <v-select label="Name" class="mw-full" multiple v-model="DoctorSearch.SpecialtyIDs" :options="specialities"
          :reduce="(ID) => ID.ID" />
      </div>
      <!-- <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
            <label class="text-sm opacity-75">{{ $t("Rate") }}</label>
            <vs-input
              v-model="search.Rate"
              class="w-full"
              name="Email"
              type="number"
              v-validate="'email'"
            />
          </div> -->
        <!-- DoctorName -->
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Doctor Name") }}</label>
          <vs-input v-model="DoctorSearch.DoctorName" class="w-full" name="DoctorName" />
        </div>
        <!-- HospitalID -->
        <!-- <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Hospital") }}</label>
          <v-select label="Name" class="mw-full" v-model="DoctorSearch.HospitalID" :options="Hospitals"
            :reduce="(ID) => ID.ID" />
        </div> -->
        <!-- AssignedAccountManagerID -->
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4" v-if="$acl.check('admin')">
          <label class="text-sm opacity-75">{{ $t("User access") }}</label>
          <v-select label="UserName" class="w-full" v-model="DoctorSearch.AssignedAccountManagerID" :options="users"
            :reduce="(ID) => ID.Id" />
        </div>
        <!-- AppointmentDateFrom -->
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{
            $t("Online appointments Date From")
          }}</label>
          <datepicker placeholder="from" v-model="DoctorSearch.AppointmentDateFrom" class="vx-col w-full" name="from">
          </datepicker>
        </div>
        <!-- AppointmentDateTo -->
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{
            $t("Online appointments Date To")
          }}</label>
          <datepicker placeholder="from" v-model="DoctorSearch.AppointmentDateTo" class="vx-col w-full" name="from">
          </datepicker>
        </div>
        <!-- SurgeryDateFrom -->
        <!-- <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{
            $t("Surgeries & treatments Date From")
          }}</label>
          <datepicker placeholder="from" v-model="DoctorSearch.SurgeryDateFrom" class="vx-col w-full" name="from">
          </datepicker>
        </div> -->
        <!-- SurgeryDateTo -->
        <!-- <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{
            $t("Surgeries & treatments Date To")
          }}</label>
          <datepicker placeholder="from" v-model="DoctorSearch.SurgeryDateTo" class="vx-col w-full" name="from">
          </datepicker>
        </div> -->
        <div class="vx-row m-2 w-full">
          <vs-button class="my-6 m-2 rounded-sm" @click="doctorSearchFirst" color="warning">{{ $t("Search") }}</vs-button>
          <vs-button class="my-6 m-2 rounded-sm" @click="resetData()" color="warning">{{
            $t("Reset")
          }}</vs-button>
        </div>
      </div>
    </vx-card>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base" v-for="item in doctors" :key="item.ID"
        @click="$router.push(item.url).catch(() => { })">
        <vx-card class="vxCardPatient text-center cursor-pointer rounded-xl">
          <div class="vx-row ml-3 mr-3" style="justify-content: right">
            <feather-icon icon="TrashIcon" svgClasses="w-7 h-7 hover:text-primary stroke-current"
              @click="openConfirm(item)" />
          </div>
          <div class="vx-row">
            <imageLazy :imageStyle="{
              'border-radius': '40px',
              width: '80px',
              height: '80px',
              size: '100px',
            }" :imageClass="'ml-5'" :src="baseURL + item.ProfileImagePath" placeHolderType="doctor" />
            <div class="vx-col mb-3 mt-3 m-3">
              <h4 class="mb-2" v-if="item.FullName">{{ item.FullName }}</h4>
              <h4 class="mb-2" v-else>{{ item.Name }}</h4>
              <small @click="editData(item)">{{ "Profile Data" }}</small>
            </div>
          </div>
          <div class="vx-row ml-3">
            <vs-button class="m-1 customizer-btn rounded-lg text-sm" color="warning" type="filled"
              @click="ShowAppointments(item)">{{ $t("Appointment") }}</vs-button>
            <vs-button class="m-1 customizer-btn rounded-lg text-sm" color="warning" type="filled"
              @click.stop="ShowSessions(item)">{{ $t("Availability Schedule ") }}</vs-button>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import { domain } from "@/gloabelConstant.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleGender from "@/store/settings/gender/moduleGender.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleLanguage from "@/store/settings/language/moduleLanguage.js";
import { BAvatar } from "bootstrap-vue";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import moduleUser from "@/store/user/moduleUser.js";
import Datepicker from "vuejs-datepicker";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components: {
    BAvatar,
    Datepicker,
    imageLazy,
  },
  data() {
    return {
      search: {
        IsPaging: true,
        PageNumber: 1,
        PageSize: 20,
        SmallSearch: true
      },
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      baseURL: domain,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false,
      searchHospital: {},
    };
  },
  computed: {
    DoctorSearch() {
      var obj = this.$store.state.DoctorList.DoctorSearchObj;
      obj.SmallSearch = true;
      return obj;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    doctors() {
      return this.$store.state.DoctorList.doctors;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    genders() {
      return this.$store.state.GenderList.genders;
    },
    specialities() {
      return this.$store.state.SpecialtyList.specialities;
    },
    languages() {
      return this.$store.state.LanguageList.languages;
    },
    Hospitals() {
      return this.$store.state.HospitalList.Hospitals;
    },
    users() {
      return this.$store.state.UserList.users;
    },
  },

  methods: {
    doctorSearch() {
      this.$store.dispatch("DoctorList/SearchDoctorsPaging", this.DoctorSearch).then(res => {
        if (document.documentElement.scrollHeight <= window.innerHeight && res.data.Data.length >= this.DoctorSearch.PageSize) {
          this.DoctorSearch.PageNumber++;
          this.doctorSearch();
        }

      });
    },
    doctorSearchFirst() {
      this.DoctorSearch.PageNumber = 1;
      debugger;
      this.doctorSearch();
    },
    HospitalSearch() {
      this.$store.dispatch("HospitalList/GetAllHospitals", this.searchHospital);
    },
    addNewData() {

      this.$router.push({ name: "doctor-add/Edit" });
    },

    //  deleteData(data) {
    //    debugger;
    //    this.$store.dispatch("DoctorList/DeleteDoctor", data).catch(
    //      (err) =>
    //    {
    //     console.error(err);
    //     window.showDeleteFailed(err.response.data.Message);
    // });
    //  },

    deleteData(data) {
      debugger;
      this.$vs.loading.close();
      this.$store.dispatch("DoctorList/DeleteDoctor", data).then(() => {
        window.showDeleteSuccess();
        this.$vs.loading.close();
      })
        .catch((err) => {
          debugger;
          window.showDeleteFailed(err.response.data.Message);
        });
    },

    editData(data) {
      this.$router.push({ name: "doctor-add/Edit", params: { ID: data.ID } });
    },

    ShowAppointments(data) {
      this.$router.push({
        name: "ReservationSession",
        params: { ID: data.ID },
      });
    },
    ShowSessions(data) {
      this.$router.push({
        name: "AvailabilitySchedule",
        params: { ID: data.ID },
      });
    },
    resetData() {
      this.$store.state.DoctorList.DoctorSearchObj = {};

      this.DoctorSearch.PageNumber = 1;
      this.DoctorSearch.IsPaging = true;
      this.DoctorSearch.PageSize = 20;

      this.$store.commit("DoctorList/SET_Doctor", []);

      this.doctorSearch();
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },

    acceptAlert() {
      debugger;
      this.deleteData(this.rowDataForDelete)
    },
  },
  destroyed() {
    window.onscroll = () => { };
  },
  created() {
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("UserList", moduleUser);
      moduleUser.isRegistered = true;
    }

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (!moduleGender.isRegistered) {
      this.$store.registerModule("GenderList", moduleGender);
      moduleGender.isRegistered = true;
    }
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleLanguage.isRegistered) {
      this.$store.registerModule("LanguageList", moduleLanguage);
      moduleLanguage.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    this.HospitalSearch();
    this.$vs.loading();
    var userSearch = {};
    userSearch.UserType = "AccountManager";
    this.$store.dispatch("UserList/UserSearch", userSearch).then(() => {
      this.$vs.loading.close();
    });
    if (this.languages.length == 0)
      this.$store.dispatch("LanguageList/GetAllLanguages");
    if (this.specialities.length == 0)
      this.$store.dispatch("SpecialtyList/GetAllSpecialties");
    if (this.genders.length == 0)
      this.$store.dispatch("GenderList/GetAllGenders");
    if (this.countries.length == 0)
      this.$store.dispatch("CountryList/GetAllCountries");
    // this.$store.commit("DoctorList/SET_Doctor", []);

    this.doctorSearch();
  },

  mounted() {
    window.onscroll = () => {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight + 80 >=
        document.documentElement.scrollHeight;
      if (bottomOfWindow) {
        if (this.doctors.length > 0) {
          this.DoctorSearch.PageNumber++;
          this.doctorSearch();
        }
      }

    };
  },
};
</script>

<style lang="scss">
small {
  color: rgb(255, 115, 0);
  text-decoration: underline rgb(255, 115, 0);
}

.vxCard {
  height: 200px !important;
  max-height: 200px !important;
  max-height: 200px !important;
}

.vxCard:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0%;
  margin-left: 10%;
  z-index: 500000;
  width: 25%;
  height: 20%;
  border-top: 3px solid rgb(255, 115, 0) !important;
  border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
  border-right: 0 none rgba(255, 0, 0, 0.9) !important;
  border-left: 0 none rgba(0, 0, 0, 0.9) !important;
}

#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
